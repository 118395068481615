<template>
  <div>
    <div id="model1">
      <a-modal
        :visible="visible3"
        :confirm-loading="confirmLoading"
        :cancel-button-props="{ style: { display: 'none' } }"
        :ok-button-props="{ style: { display: 'none' } }"
        @ok="handleOk"
        @cancel="handleCancel"
      >
        <div slot="title" style="width: 100%">
          报警照片 （{{ this.childValuePl[item].ws_name }}）
        </div>
        <!-- 报警照片 -->
        <viewer>
          <div class="picture_wrap">
            <div class="pic">
              <img :src="this.childValuePl[item].e_img" alt="" />
            </div>
          </div>
        </viewer>
        <!-- 整洁度级别单选框 -->
        <div class="ZJD_Jibie">
          <span>整洁度级别：</span>
          <!--  :default-value="`${this.childValuePl[item].e_events}`" -->
          <a-radio-group
            name="radioGroup"
            @change="changeradioValue"
            :value="childValuePl[item].e_events"
          >
            <a-radio :value="优"> 优</a-radio>
            <a-radio :value="良"> 良</a-radio>
            <a-radio :value="差"> 差</a-radio>
            <a-radio :value="摄像头歪曲"> 摄像头歪曲</a-radio>
          </a-radio-group>
        </div>
        <!-- 上一条下一条按钮 -->
        <div class="BTN">
          <div><a-button @click="back()">上一条</a-button></div>
          <div>
            <span> <span>*纠正后，数据第实时生效，之后不可恢复</span></span>
            <span class="BTN_right">
              <a-button @click="TG_next()">跳过，下一条</a-button>
              <a-button @click="YSH_next()" type="primary">
                已审核，下一条
              </a-button>
            </span>
          </div>
        </div>
      </a-modal>
    </div>
  </div>
</template>
<script>
import { GetPickup } from "@/api/image";
export default {
  components: {},
  props: {
    childValuePl: {
      type: Array,
      default: [],
    },
    visible3: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ModalText: "报警图片",
      confirmLoading: false,
      editable: false,
      visible: "",
      value: "",
      item: 0,
      优: "优",
      良: "良",
      差: "差",
      摄像头歪曲: "摄像头歪曲",
      queryParam: {},
      si_result: "",
    };
  },
  mounted() {
    // if (this.childValuePl[this.item].e_events == "优") {
    //   this.si_result = "1";
    // } else if (this.childValuePl[this.item].e_events == "良") {
    //   this.si_result = "2";
    // } else if (this.childValuePl[this.item].e_events == "差") {
    //   this.si_result = "3";
    // } else if (this.childValuePl[this.item].e_events == "摄像头歪曲") {
    //   this.si_result = "4";
    // }
  },

  methods: {
    loadData(parameter) {
      this.queryParam = {
        ws_id: this.childValuePl[this.item].s_ws_id, //工地id  s_ws_id
        s_id: this.childValuePl[this.item].s_id, //快照id s_id
        si_type: "2", //工地整洁度
        si_result: this.si_result, //快照修改后结果
      };
      const param = Object.assign({}, parameter, this.queryParam);
      const orderParam = ["ws_id", "s_id", "si_type", "si_result"];
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetPickup(params).then((res) => {
        console.log(res);
        if (res.data == "必填字段为空，请确认后重传") {
          this.$message.error("未选择整洁度级别");
        } else if (res.data == "AI结果纠正成功") {
          this.$message.success("纠正成功");
        }
      });
    },
    handleOk(e) {
      // this.ModalText = "The modal will be closed after two seconds";
      this.confirmLoading = true;
      console.log("handleok");
      setTimeout(() => {
        // this.visible = this.visible2;
        this.visible = false;
        this.$emit("ZjdJiuzhengPl", this.visible);
        this.confirmLoading = false;
      }, 500);
    },

    handleCancel(e) {
      console.log("Clicked cancel button");
      // this.visible = this.visible2;
      this.item = 0;
      this.visible = false;
      this.$emit("ZjdJiuzhengPl", this.visible);
    },

    changeradioValue(e) {
      console.log(e.target.value);
      if (e.target.value == "优") {
        this.si_result = "1";
      } else if (e.target.value == "良") {
        this.si_result = "2";
      } else if (e.target.value == "差") {
        this.si_result = "3";
      } else if (e.target.value == "摄像头歪曲") {
        this.si_result = "4";
      }
      this.childValuePl[this.item].e_events = e.target.value;
    },
    back() {
      console.log("点击了上一条");
      if (this.item == 0) {
        this.$message.warning("已是此页第一条");
        this.item = 0;
      } else {
        this.item = parseInt(this.item - 1);
      }
    },
    TG_next() {
      console.log("点击了跳过，下一条");
      if (this.item == parseInt(this.childValuePl.length - 1)) {
        this.$message.warning("已是此页最后一条");
        this.item = parseInt(this.childValuePl.length - 1);
      } else {
        this.item = parseInt(this.item + 1);
      }
    },
    YSH_next() {
      console.log("点击了已审核，下一条");
      this.loadData();

      if (this.item == parseInt(this.childValuePl.length - 1)) {
        this.$message.warning("已是此页最后一条");
        this.item = parseInt(parseInt(this.childValuePl.length - 1));
      } else {
        this.item = parseInt(this.item + 1);
      }
    },
  },
};
</script>
<style scoped lang="less">
/deep/.ant-modal {
  // width: 787px;
  // width: 900px;
  height: 50%;
  top: 2px;
  position: relative;
  display: flex;
  justify-content: center;
  margin: auto;
  margin-top: 60px;
}
/deep/.ant-modal-content {
  width: 1150px;
  // height: 80%;
  position: absolute;
  // top: 5%;
}
/deep/.ant-modal-body {
  // height: 625px;
  height: 730px;
}

.picture_wrap {
  margin: 0 10px 10px 10px;
  border-radius: 4px;
  display: flex;
}
.pic {
  min-width: 100px;
  min-height: 100px;
  width: 30%;
  margin-right: 1%;
  border: 1px solid #cdcdcd;
  border-radius: 6px;
  img {
    width: 1080px;
    height: 720px;
    height: auto;
    margin: auto;
  }
}
.BTN {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  .BTN_right {
    .ant-btn {
      margin-left: 10px;
    }
  }
}
</style>
