<template>
  <div>
    <div id="model1">
      <a-modal
        :visible="visible2"
        :confirm-loading="confirmLoading"
        @ok="handleOk"
        @cancel="handleCancel"
      >
        <div slot="title" style="width: 100%">
          报警照片（{{ this.childValue.ws_name }}）
        </div>
        <viewer>
          <div class="picture_wrap">
            <div class="pic">
              <img
                :src="this.childValue.e_img"
                :id="this.childValue.e_img"
                @click="fangdaImg()"
              />
            </div>
          </div>
        </viewer>
        <div class="ZJD_Jibie">
          <span>整洁度级别：</span>
          <!--   :default-value="`${this.childValue.e_events}`" -->
          <a-radio-group
            name="radioGroup"
            @change="changeradioValue"
            :value="childValue.e_events"
          >
            <a-radio :value="优"> 优</a-radio>
            <a-radio :value="良"> 良</a-radio>
            <a-radio :value="差"> 差</a-radio>
            <a-radio :value="摄像头歪曲">摄像头歪曲</a-radio>
          </a-radio-group>
        </div>
        <div class="last">
          <a-radio-group name="radioGroup" :default-value="1">
            <a-radio :value="YiShenHe" disabled
              ><span>{{
                childValue.s_process == "1" ? "未审核" : "已审核"
              }}</span></a-radio
            >
          </a-radio-group>
          *纠正后，数据实时生效，之后不可恢复
        </div>
      </a-modal>
    </div>
  </div>
</template>
<script>
import { GetPickup } from "@/api/image";
export default {
  components: {},
  props: {
    childValue: {
      type: Object,
      default: {},
    },
    visible2: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ModalText: "报警图片",
      confirmLoading: false,
      editable: false,
      visible: "",
      YiShenHe: 1,
      visible3: false,
      value: "",
      RG_value: "",
      queryParam: {},
      si_result: "",
      优: "优",
      良: "良",
      差: "差",
      摄像头歪曲: "摄像头歪曲",
    };
  },
  mounted() {
    if (this.childValue.e_events == "优") {
      this.si_result = "1";
    } else if (this.childValue.e_events == "良") {
      this.si_result = "2";
    } else if (this.childValue.e_events == "差") {
      this.si_result = "3";
    } else if (this.childValue.e_events == "摄像头歪曲") {
      this.si_result = "4";
    }
  },

  methods: {
    loadData(parameter) {
      this.queryParam = {
        ws_id: this.childValue.s_ws_id, //工地id  s_ws_id
        s_id: this.childValue.s_id, //快照id s_id
        si_type: "2", //工地整洁度
        si_result: this.si_result, //快照修改后结果
      };
      const param = Object.assign({}, parameter, this.queryParam);
      const orderParam = ["ws_id", "s_id", "si_type", "si_result"];
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetPickup(params).then((res) => {
        console.log(res);
        if (res.data != "必填字段为空，请确认后重传") {
          this.$message.success(res.data);
        }
      });
    },
    handleOk(e) {
      // this.ModalText = "The modal will be closed after two seconds";
      this.confirmLoading = true;
      setTimeout(() => {
        // this.visible = this.visible2;
        this.visible = false;
        this.$emit("ZjdJiuzheng", this.visible);
        this.confirmLoading = false;
        this.loadData();
      }, 500);
    },

    handleCancel(e) {
      console.log("Clicked cancel button");
      // this.visible = this.visible2;
      this.visible = false;
      this.$emit("ZjdJiuzheng", this.visible);
    },

    handleOk3(e) {
      this.visible3 = false;
    },
    fangdaImg() {},
    changeradioValue(e) {
      console.log("e.target.value", e.target.value);
      if (e.target.value == "优") {
        this.si_result = "1";
      } else if (e.target.value == "良") {
        this.si_result = "2";
      } else if (e.target.value == "差") {
        this.si_result = "3";
      } else if (e.target.value == "摄像头歪曲") {
        this.si_result = "4";
      }
      this.childValue.e_events = e.target.value;
    },
  },
};
</script>
<style scoped lang="less">
/deep/.ant-modal {
  width: 787px;
  height: 50%;
  top: 2px;
  position: relative;
  display: flex;
  justify-content: center;
  margin: auto;
  margin-top: 60px;
}
/deep/.ant-modal-content {
  width: 1080px;
  // height: 80%;
  position: absolute;
  // top: 5%;
}
/deep/.ant-modal-body {
  // height: 625px;
  height: 680px;
}
.last {
  // background-color: pink;
  margin-top: 15px;
  width: 100%;
  // margin-left: 400px;
  text-align: right;
}

.picture_wrap {
  margin: 0 10px 10px 10px;
  border-radius: 4px;
  display: flex;
}
.pic {
  min-width: 100px;
  min-height: 100px;
  width: 30%;
  margin-right: 1%;
  border: 1px solid #cdcdcd;
  border-radius: 6px;
  img {
    width: 1000px;
    height: auto;
  }
}
</style>
