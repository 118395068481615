<template>
  <div>
    <s-search
      :queryItems="queryItems"
      @fetchData="fetchUsers"
      :SiteNeat="SiteNeat"
      :Attention_bc_name="Attention_bc_name"
      ref="SSearch"
    ></s-search>

    <div class="table-button">
      <a-button
        type="primary"
        @click="PiLiangSHZP"
        v-if="isShowBtns.indexOf('SiteNeat-/api/snapshot/pickup') > -1"
        >批量审核照片</a-button
      >
      <a-button
        type="plain"
        icon="cloud-download"
        @click="handleExport"
        v-if="isShowBtns.indexOf('SiteNeat-/api/worker/imageexport') > -1"
        >导出</a-button
      >
      <a-button type="plain" icon="redo" @click="$refs.table.refresh(true)"
        >刷新</a-button
      >
      <columns-set
        tableName="SiteNeat"
        :columns="columns"
        @changeColumns="changeColumns"
      ></columns-set>
    </div>

    <s-table
      ref="table"
      size="small"
      rowKey="e_img"
      :columns="tableColumns"
      :data="loadData"
      :showPagination="true"
      :scroll="{ x: 1500, y: 'calc(82vh - 150px )' }"
    >
      <span slot="serial" slot-scope="text, record, index">
        <!-- {{ (pageNo - 1) * pageSize + index + 1 }} -->
        {{ (pageNo - 1) * pageSize + index + 1 }}
      </span>

      <!-- 整洁度级别 -->
      <span slot="e_events" slot-scope="text">
        {{ text }}
      </span>
      <!-- 工地名称 -->
      <span slot="ws_name" slot-scope="text">
        <a-tooltip placement="topLeft">
          <template slot="title">
            <span>{{ text }}</span>
          </template>
          <span @click="jumpToSiteList(text)" class="cursor_pointer">{{
            text.replace(/直辖市|-北京|-天津/g, "")
          }}</span>
        </a-tooltip>
      </span>
      <!-- 照片 -->
      <span slot="e_img" slot-scope="text, record" class="info_out">
        <span class="info" @click="showInfo(text, record.ws_name)">
          <div class="actions">
            <div class="icon_out">
              <img :src="text" width="100%" class="imgname" />
              <a-icon type="zoom-in" class="icon" />
            </div>
          </div>
          <div>
            {{ record.d_imei }}
          </div>
        </span>
      </span>
      <!-- 时间 -->
      <span slot="e_time" slot-scope="text"> {{ $Format(text) }} </span>
      <!-- 事件分数 -->
      <span slot="e_score" slot-scope="text"> {{ text }} </span>
      <!-- 审核状态 -->
      <span slot="s_process" slot-scope="text">
        {{ text == "1" ? "未审核" : "已审核" }}
      </span>
      <span slot="si_correct" slot-scope="text">{{
        text | SitCorrectStatus
      }}</span>
      <!-- 操作 -->
      <span slot="action" slot-scope="text, record, index">
        <a
          @click="OpenDrawer('SitePreview', '直播', record)"
          class="OpenDrawerPreview"
          v-if="isShowBtns.indexOf('SiteNeat-/api/worksite/camera/video') > -1"
          >直播</a
        >
        <a
          @click="handele(text, record, index)"
          href="javascript:;"
          v-if="isShowBtns.indexOf('SiteNeat-/api/snapshot/pickup') > -1"
          >纠正</a
        >
      </span>
    </s-table>

    <a-modal
      class="tips-modal"
      title="提示"
      okText="确定"
      cancelText="取消"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="exportOk"
      @cancel="exportCancel"
    >
      <p>确定导出？</p>
    </a-modal>
    <a-modal
      width="1080px"
      :footer="null"
      :visible="visibleImg"
      @cancel="imgCancel"
    >
      <div slot="title" style="width: 100%">
        工地整洁度照片（{{ ImgName }}）
      </div>
      <div v-if="Image.length > 77" style="width: 100%" id="Image">
        <img :src="Image" width="100%" />
        <div id="infoMenu">
          <div @click="DownloadPic(Image)">下载图片</div>
        </div>
      </div>
      <div v-else>暂无图片</div>
    </a-modal>
    <zjd-jiuzheng
      :visible2="visible2"
      @ZjdJiuzheng="ZjdJiuzheng"
      :childValue="childValue"
    />
    <zjd-jiuzheng-pl
      :visible3="visible3"
      @ZjdJiuzhengPl="ZjdJiuzhengPl"
      :childValuePl="childValuePl"
    ></zjd-jiuzheng-pl>
    <a-drawer
      :title="BasicDrawerName"
      placement="right"
      :visible="visibleDrawer"
      :after-visible-change="afterVisibleChange"
      :destroyOnClose="true"
      @close="onClose"
      width="800px"
    >
      <SitePreview
        :record="record"
        v-if="SitePreview"
        ref="SitePreview"
      ></SitePreview>
    </a-drawer>
  </div>
</template>

<script>
import { STable, SSearch, ColumnsSet } from "@/components";
import { GetSiteNeat } from "@/api/image";
import { ExportWorkers, ExportSiteNeat } from "@/api/export";
import { urlConfig } from "@/config/config";
import { requestImage } from "@/utils/fileRequest";
import ZjdJiuzheng from "./ZjdJiuzheng.vue";
import ZjdJiuzhengPl from "@/views/image/ZjdJiuzhengPl.vue";
import moment from "moment";
import SitePreview from "../site/SitePreview.vue";

// 搜索条件
const queryItems = [
  { label: "工地名称", value: "ws_name", type: "input" },
  {
    label: "工地所属分公司",
    value: "bc_name",
    type: "select",
    selectOption: "CompanyList",
    defaultValue: "",
  },
  {
    label: "整洁度类型",
    value: "e_events",
    type: "select",
    selectOption: "SiteNeatLevel",
    defaultValue: "",
  },

  { label: "项目经理", value: "w_name_jl", type: "input", defaultValue: "" },
  { label: "工程管家", value: "w_name_gj", type: "input" },
  { label: "合同编号", value: "ws_only", type: "input" },
  {
    label: "整洁管理日期查询",
    value: "e_time",
    type: "dateRange",
    range: ["e_time_start", "e_time_end"],
    keyType: "WorkerAttend",
    defaultValue: [moment(new Date().getTime()), moment(moment(new Date()))],
  },
  // {
  //   label: "纠正状态",
  //   // value: "e_events",
  //   type: "select",
  //   // selectOption: "SiteNeatLevel",
  // },
  // {
  //   label: "审核状态",
  //   // value: "e_events",
  //   type: "select",
  //   selectOption: "SiteNeatLevel",
  // },
];
const orderParam = [
  "bc_name",
  "ws_name",
  "e_events",
  "e_time_start",
  "e_time_end",
  "w_name_jl",
  "w_name_gj",
  "ws_only",
  "pageNo",
  "pageSize",
];
const columns = [
  {
    title: "序号",
    scopedSlots: { customRender: "serial" },
    width: "70px",
    dataIndex: "serial",
    align: "center",
  },
  {
    title: "整洁度类型",
    width: "140px",
    dataIndex: "e_events",
    scopedSlots: { customRender: "e_events" },
    align: "center",
  },
  {
    title: "报警时间",
    width: "110px",
    dataIndex: "e_time",
    scopedSlots: { customRender: "e_time" },
    align: "center",
  },
  {
    title: "工地整洁度照片",
    width: "180px",
    dataIndex: "e_img",
    scopedSlots: { customRender: "e_img" },
  },

  {
    title: "工地所属分公司",
    width: "120px",
    dataIndex: "bc_name",
    align: "center",
  },
  {
    title: "工地管家",
    width: "90px",
    dataIndex: "w_name_gj",
    align: "center",
  },
  {
    title: "项目经理",
    width: "90px",
    dataIndex: "w_name_jl",
    align: "center",
  },
  {
    title: "工地名称",
    // width: "200px",
    dataIndex: "ws_name",
    scopedSlots: { customRender: "ws_name" },
    ellipsis: true,
  },
  {
    title: "合同编号",
    width: "120px",
    dataIndex: "ws_only",
    align: "center",
  },
  // {
  //   title: "事件分数",
  //   width: "90px",
  //   dataIndex: "e_score",
  //   scopedSlots: { customRender: "e_score" },
  //   align: "center",
  // },
  // 新增
  // {
  //   title: "审核状态",
  //   width: "100px",
  //   dataIndex: "s_process",
  //   scopedSlots: { customRender: "s_process" },
  //   align: "center",
  // },
  // {
  //   title: "纠正状态",
  //   width: "100px",
  //   dataIndex: "si_correct",
  //   scopedSlots: { customRender: "si_correct" },
  //   align: "center",
  // },
  {
    title: "操作",
    width: "100px",
    // dataIndex: "e_score",
    scopedSlots: { customRender: "action" },
    dataIndex: "action",
    align: "center",
    fixed: "right",
    ellipsis: true,
  },
];

export default {
  name: "SiteNeat",
  components: {
    STable,
    SSearch,
    ColumnsSet,
    ZjdJiuzheng,
    ZjdJiuzhengPl,
    SitePreview,
  },
  data() {
    this.queryItems = queryItems;
    this.columns = columns;
    return {
      tableColumns: [],
      // 查询参数
      queryParam: {
        ws_name: "",
        bc_name: "",
        w_name_jl: "",
        w_name_gj: "",
        ws_only: "",
        e_events: "",
        e_time_start: Math.round(new Date().getTime() / 1000),
        e_time_end: Math.round(new Date().getTime() / 1000),
      },
      // 人脸信息图片地址
      pathUrl:
        urlConfig.baseUrl + "/files/picture/snapshot/snapshotpicture?filePath=",
      visible: false,
      confirmLoading: false,
      //当前页数，当前条数
      pageNo: 1,
      pageSize: 10,
      // 查看图片
      visibleImg: false,
      Image: "",
      ImgName: "",
      childValue: {},
      childValuePl: [],
      visible2: false,
      visible3: false,
      SiteNeat: "",
      Attention_bc_name: "",
      visibleDrawer: false,
      BasicDrawerName: "",
      SitePreview: false,
      isShowBtns: [],
    };
  },
  created() {
    this.tableColumns = columns;
    this.changeColumns();
    // 默认今天的日期
    this.queryParam.e_time_start = Math.round(new Date().getTime() / 1000);
    this.queryParam.e_time_end = Math.round(new Date().getTime() / 1000);
    this.queryItems[6].defaultValue = [
      moment(new Date().getTime()),
      moment(new Date().getTime()),
    ];
    // console.log(this.queryParam.e_time_start, this.queryParam.e_time_end);
    if (this.$route.params) {
      this.queryItems[2].defaultValue = this.$route.params.e_events;
      this.queryParam.e_events = this.$route.params.e_events;
      // console.log(this.queryItems[2].defaultValue);
      // this.queryParam.e_time_start = this.$route.params.e_time_start;
      // this.queryParam.e_time_end = this.$route.params.e_time_end;
    }
    // this.queryParam.e_events = this.$route.params.e_events;

    if (this.$route.params.e_events) {
      setTimeout(() => {
        this.$refs.SSearch.expandChange();
      }, 10);
      this.SiteNeat = this.$route.params.e_events;
      this.Attention_bc_name = this.$route.params.bc_id;
      this.queryParam.bc_name = this.$route.params.bc_id;
    } else {
      this.SiteNeat = "";
      this.Attention_bc_name = "";
    }
    if (this.$route.params.e_time_start) {
      this.queryParam.e_time_start = this.$route.params.e_time_start;
      this.queryParam.e_time_end = this.$route.params.e_time_end;
      this.queryItems[6].defaultValue = [
        moment(this.$Format(this.$route.params.e_time_start)),
        moment(this.$Format(this.$route.params.e_time_end)),
      ];
    } else {
    }
    // 管理端看板跳转传值
    if (this.$route.query.e_events) {
      console.log(this.$route.query);
      // 默认值的改变
      for (let item in this.$route.query) {
        // 默认值的改变
        this.queryItems.forEach((el) => {
          if (el.value == item) {
            el.defaultValue = this.$route.query[item];
          }
        });
        // 查询值的改变
        for (let index in this.queryParam) {
          // console.log(this.queryParam);
          if (index == item) {
            this.queryParam[index] = this.$route.query[item];
          }
          // console.log(this.queryParam[index]);
        }
      }
      // 处理 工地形象统计->按组织统计跳转  分公司名称
      this.SiteNeat = this.$route.query.e_events;
      this.Attention_bc_name = this.$route.query.bc_id;
      this.queryParam.bc_name = this.$route.query.bc_id;
    }
    if (this.$route.query.e_time_start) {
      this.queryParam.e_time_start = this.$route.query.e_time_start;
      this.queryParam.e_time_end = this.$route.query.e_time_end;
      this.queryItems[6].defaultValue = [
        moment(this.$Format(this.$route.query.e_time_start)),
        moment(this.$Format(this.$route.query.e_time_end)),
      ];
    }
    this.loadData();
  },
  mounted() {
    this.isShowBtns = sessionStorage.getItem("isShowBtns").split(",");
  },
  // 纠正弹窗关闭，刷新表格
  watch: {
    visible2(NValue, OValue) {
      if (NValue == false) {
        this.$refs.table.loadData(); //刷新当前页表格
      }
    },
    visible3(NValue, OValue) {
      if (NValue == false) {
        this.$refs.table.loadData(); //刷新当前页表格
      }
    },
    visibleImg(newValue) {
      console.log(newValue);
      if (newValue == true) {
        setTimeout(() => {
          let boxEle = document.getElementById("Image");
          // console.log(boxEle);
          // 取消原本的点击事件
          boxEle.oncontextmenu = function (e) {
            e.preventDefault();
            let infoMenu = document.getElementById("infoMenu");
            infoMenu.style.display = "block";
            // 位置（点在哪出现在哪)
            infoMenu.style.left = e.pageX - 110 + "px";
            infoMenu.style.top = e.pageY - 110 + "px";
          };
          window.onclick = (e) => {
            let infoMenu = document.querySelector("#infoMenu");
            if (infoMenu && !infoMenu.contains(e.target)) {
              infoMenu.style.display = "none";
            } //contains包含
          };
        });
      }
    },
  },
  methods: {
    // 自定义表头列
    changeColumns() {
      let ColumnsSet = JSON.parse(localStorage.getItem("SiteNeatColumnsSet"));
      let value = ColumnsSet ? ColumnsSet : [];
      let selectColumns = columns.filter((el) => value.includes(el.dataIndex));
      this.tableColumns = selectColumns.length ? selectColumns : columns;
    },
    // 加载数据方法 必须为 Promise 对象
    loadData(parameter) {
      this.pageNo = parameter.pageNo * 1;
      this.pageSize = parameter.pageSize;
      const param = Object.assign({}, parameter, this.queryParam);

      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        // ws_status: "",
        // d_status: "",
        // ws_bind_status: "",
        verify: this.$verify(requestParameters, orderParam),
      };
      // console.log(this.queryItems[2]);
      return GetSiteNeat(params).then((res) => {
        res.data.pageNo = res.data.page * 1;
        res.data.totalCount = res.data.sum;
        const url = this.pathUrl;
        let data = res.data.data;
        // console.log(data);
        this.childValuePl = data;
        data.forEach((el, i) => {
          let img = el.e_img;
          el.e_img = url + img;
          // console.log(el.e_events);
        });
        return res.data;
      });
    },
    fetchUsers(value) {
      this.queryParam = value;
      this.$refs.table.refresh(true);
    },
    handleExport() {
      this.confirmLoading = false;
      this.visible = true;
    },
    exportOk() {
      this.confirmLoading = true;
      const params = {
        ws_name: this.queryParam.ws_name,
        e_events: this.queryParam.e_events,
        bc_name: this.queryParam.bc_name,
        e_time_start: this.queryParam.e_time_start,
        e_time_end: this.queryParam.e_time_end,
        ws_only: this.queryParam.ws_only,
        w_name_gj: this.queryParam.w_name_gj,
        w_name_jl: this.queryParam.w_name_jl,
      };
      ExportSiteNeat(params).then((res) => {
        var blob = res;
        var reader = new FileReader();
        reader.readAsDataURL(blob); // 转换为base64，可以直接放入a标签href
        reader.onload = function (e) {
          // 转换完成，创建一个a标签用于下载
          var a = document.createElement("a");
          a.download = "整洁管理列表.xls";
          a.href = e.target.result;
          // $("body").append(a);    // 修复firefox中无法触发click
          a.click();
          a.remove();
        };
        this.visible = false;
        this.confirmLoading = false;
      });
    },
    exportCancel() {
      this.visible = false;
    },
    showInfo(url, ws_name) {
      this.visibleImg = true;
      this.Image = "";
      this.ImgName = ws_name;
      let that = this;
      that.Image = url;

      // this.Image = "";
      // let that = this;
      // requestImage(this.pathUrl + url, function (url) {
      // that.Image = url;
      // });
    },
    imgCancel() {
      this.visibleImg = false;
    },
    // 操作按钮
    handele(text, record, index) {
      console.log(text, record, index);
      this.childValue = record;
      console.log(this.childValue);
      this.visible2 = true;
      // console.log(this.visible2);
    },
    // 直播
    OpenDrawer(temp, title, record) {
      console.log(temp, title, record);
      this.visibleDrawer = true;
      this.BasicDrawerName = title;
      this.SitePreview = true;
      let ws_id = { ws_id: record.s_ws_id };
      Object.assign(record, ws_id);
      this.record = record;
    },
    onClose() {
      this.visibleDrawer = false;
      this.SitePreview = false;
    },
    afterVisibleChange(val) {},
    ZjdJiuzheng(visible) {
      this.visible2 = visible;
    },
    ZjdJiuzhengPl(visible) {
      this.visible3 = visible;
    },
    PiLiangSHZP() {
      console.log("点击了批量审核照片");
      // console.log(this.childValuePl);
      this.visible3 = true;
    },
    // 获取前i天的日期
    getLastSevenDays(i) {
      var date = new Date(),
        timestamp,
        newDate;
      if (!(date instanceof Date)) {
        date = new Date(date.replace(/-/g, "/"));
      }
      timestamp = date.getTime();
      newDate = new Date(timestamp - i * 24 * 3600 * 1000);
      var month = newDate.getMonth() + 1;
      month = month.toString().length == 1 ? "0" + month : month;
      var day =
        newDate.getDate().toString().length == 1
          ? "0" + newDate.getDate()
          : newDate.getDate();
      return [newDate.getFullYear(), month, day].join("-");
    },
    // 点击工地地址跳转到工地列表页面
    jumpToSiteList(text) {
      localStorage.setItem("path", "SiteList");
      this.$router.push({
        path: "/SiteList",
        query: {
          ws_name: text,
        },
      });
    },
    // 下载图片
    DownloadPic(url) {
      fetch(url).then((res) => {
        res
          .blob()
          .then((blob) => {
            let a = document.createElement("a");
            let url = window.URL.createObjectURL(blob);
            let name = this.$PicName(new Date().getTime() / 1000);
            // || "图片.jpg"
            let filename = name + ".png";
            a.href = url;
            a.download = filename;
            a.click();
            window.URL.revokeObjectURL(url);
          })
          .then(() => {
            let infoMenu = document.querySelector("#infoMenu");
            infoMenu.style.display = "none";
          });
      });
    },
  },
};
</script>
<style scoped lang="less">
imgname :hover {
  transform: translateY(-30px);
  box-shadow: 0 0 100px rgba(0, 0, 0, 0.7);
}

// 鼠标悬停蒙层
.info_out {
  position: relative;
  // 鼠标移入变成小手
  cursor: pointer;
}
.icon_out {
  // width: 209px;
  width: 100%;
  height: 85px;
  position: relative;
  display: flex;
}
.icon {
  position: absolute;
  margin: auto;
  font-size: 25px;
  left: 43%;
  top: 40%;
  display: none;
}
.info {
  &:before {
    content: " ";
    position: absolute;
    z-index: 1;
    background-color: fade(rgba(3, 3, 3, 0.966), 25%);
    // transition: all 0.3s;
    // width: 209px;
    width: 100%;
    height: 85px;
    opacity: 0;
  }
}
.actions {
  z-index: 10;
  opacity: 1;
}
.info:hover {
  &:before {
    opacity: 1;
    position: absolute;
  }
  .actions {
    opacity: 1;
  }
  .icon {
    display: flex;
    color: white;
    z-index: 2;
  }
}
.OpenDrawerPreview {
  margin-right: 10px;
}
.info:hover + .actions,
.actions:hover {
  opacity: 1;
}
// 表头居中显示
/deep/.ant-table-thead > tr > th {
  text-align: center;
}
.cursor_pointer {
  cursor: pointer;
}
.cursor_pointer:hover {
  color: #1890ff;
}
#Image {
  position: relative;
}
/deep/#infoMenu {
  width: 130px;
  line-height: 45px;
  // height: 200px;
  background: #ffffff;
  position: absolute;
  display: none;
  // position: relative !important;
}
</style>
